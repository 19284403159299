import { child, ref as databaseRef, get, update } from "firebase/database";
import {
  getDownloadURL,
  ref as storageRef,
  uploadBytes,
} from "firebase/storage";
import { atom } from "nanostores";

import firebase from "../../shared/firebase";

class ExercisesRepositoryFirebase {
  constructor() {
    this.stores = {
      loading: atom(true),
      exercises: atom(null),
    };
  }

  async update(exercises, fields) {
    const updates = {};
    const prevExercises = this.stores.exercises.get();

    for (const exercise of exercises) {
      if (fields) {
        for (const field of fields) {
          updates[`/exercises/${exercise.id}/${field}`] = exercise[field];
        }
      } else {
        const prevExercise = prevExercises?.get(exercise.id) || {};
        if (prevExercise.image) {
          updates[`/exercises/${exercise.id}`] = {
            image: prevExercise.image,
            extension: prevExercise.extension,
            ...exercise
          };
        } else {
          updates[`/exercises/${exercise.id}`] = exercise;
        }
      }
    }

    const result = await update(databaseRef(firebase.db), updates);

    this.loadExercises();

    return result;
  }

  async loadExercises() {
    let exercises = [];

    try {
      const exercisesPath = `/exercises/`;
      const exercisesSnapshot = await get(
        child(databaseRef(firebase.db), exercisesPath)
      );

      if (exercisesSnapshot.exists()) {
        exercises = Object.values(exercisesSnapshot.val());
      }
    } catch (error) {
      console.error("ExercisesRepositoryFirebase", error);
    }

    this.stores.exercises.set(new Map(
        exercises.filter((exercise) => exercise.id).map((exercise) => [exercise.id, exercise])
    ));
    this.stores.loading.set(false);
  }

  async uploadImages(imageFiles) {
    const promises = imageFiles.map(async (imageFile) => {
      const [id, extension] = imageFile.name.split(".");

      const subFolder = extension !== "gif" ? `${extension}/` : "";

      const imageRef = storageRef(
        firebase.storage,
        `exercises/${subFolder}${imageFile.name}`
      );

      const snapshot = await uploadBytes(imageRef, imageFile, {
        cacheControl: "public, max-age=604800",
      });
      const image = await getDownloadURL(snapshot.ref);

      return {
        id,
        extension,
        image,
      };
    });

    const exercisesImages = await Promise.all(promises);

    return this.update(exercisesImages, ["image", "extension"]);
  }
}

export default ExercisesRepositoryFirebase;
