import exampleFileSrc from "../../../assets/bz55-exercises-file-example.xlsx";
import exampleImagesSrc from "../../../assets/bz55-exercises-images-example.zip";
import Button from "../../../components/button/Button";
import Divider from "../../../components/divider/Divider";
import Dropzone from "../../../components/dropzone/Dropzone";

import { useUploadExercises } from "./useUploadExercises";

function UploadExercises() {
  const { config, model, actions } = useUploadExercises();

  return (
    <div className="gap-x-8 gap-y-10 flex flex-1">
      <aside className="hidden lg:block w-72">
        <h2 className="text-base font-semibold leading-7 text-gray-900">
          Archivo de ejercicios
        </h2>

        <p className="mt-1 text-sm leading-6 text-gray-600 mb-2">
          Es necesario que el <code>.XLSX</code> incluya la siguientes las
          siguientes columnas:
        </p>

        <ul role="list" className="list-disc space-y-2 pl-4 text-sm mb-2">
          <li className="font-semibold text-gray-600">EJERCICIO</li>
          <li className="font-semibold text-gray-600">DESCRIPCIÓN</li>
          <li className="font-semibold text-gray-600">MATERIAL</li>
        </ul>

        <h2 className="text-base font-semibold leading-7 text-gray-900">
          Imagenes de ejercicios
        </h2>

        <p className="mt-1 text-sm leading-6 text-gray-600 mb-2">
          Las imágenes tiene que ser en formato <code>.GIF</code> y el nombre de
          los archivos debe ser el mismo que <strong>EJERCICIO</strong> del
          archivo de ejercicios.
        </p>

        <Divider />

        <Button
          as="a"
          href={exampleFileSrc}
          download
          target="_blank"
          rel="noreferrer"
        >
          Descargar archivo de ejemplo
        </Button>
        <Button
          as="a"
          href={exampleImagesSrc}
          download
          target="_blank"
          rel="noreferrer"
        >
          Descargar imagenes de ejemplo
        </Button>
      </aside>

      <section className="rounded-lg flex-1">
        <Dropzone
          title={model.title}
          message={model.message}
          validFormats={config.validFormats}
          loading={model.loading}
          error={model.error}
          onSuccess={actions.onSuccess}
          onFail={actions.onFail}
          onReset={actions.onReset}
        />
      </section>
    </div>
  );
}

export default UploadExercises;
