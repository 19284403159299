class User {
  #displayName;
  #email;
  #uid;
  #role;

  constructor({ displayName, email, uid, role, ...rest }) {
    this.#displayName = displayName;
    this.#email = email;
    this.#uid = uid;
    this.#role = role;
  }

  get displayName() {
    return this.#displayName;
  }

  get email() {
    return this.#email;
  }

  get uid() {
    return this.#uid;
  }

  get role() {
    return this.#role;
  }

  toString() {
    return JSON.stringify({
      displayName: this.#displayName,
      email: this.#email,
      uid: this.#uid,
      role: this.#role,
    });
  }
}

export default User;
