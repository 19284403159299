import { useStore } from "@nanostores/react";
import { useMemo, useState } from "react";

import ExercisesRepositoryFirebase from "./ExercisesRepositoryFirebase";

const exercisesRepositoryFirebase = new ExercisesRepositoryFirebase();

function useExercisesRepository() {
  const [exercisesRepository] = useState(exercisesRepositoryFirebase);
  const exercisesMap = useStore(exercisesRepository.stores.exercises);
  const loading = useStore(exercisesRepository.stores.loading);

  const exercises = useMemo(() => {
    if (exercisesMap) {
      return Array.from(exercisesMap).map(([, value]) => value);
    }

    return null;
  }, [exercisesMap]);

  return { loading, exercises, exercisesRepository };
}

export { useExercisesRepository };
