import { getDownloadURL } from "firebase/storage";
import { useState } from "react";
import readXlsxFile from "read-excel-file";

import exercisesSchema from "../../../models/exercises/exercisesSchema";
import { useExercisesRepository } from "../../../repositories/exercises/exercisesRepository";

const IDLE_STATUS = "idle";

const DEFAULT_TITLE = "Archivo con los ejercicios y sus imágenes";
const DEFAULT_MESSAGE =
  "Selecciona o arrastra un archivo .XLXS con un formato válido y sus imágenes correspondientes en formato .GIF, puedes descargar los archivos de ejemplo para revisar el formato esperado.";

const VALID_FORMATS = {
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
    ".xlxs",
  ],
  "application/vnd.ms-excel": [".xls"],
  "image/gif": [".gif"],
  "image/webp": [".webp"],
};

function findExerciseFile(files) {
  return files.find((file) => file.type.startsWith("application/vnd."));
}

function findExerciseImages(files) {
  const images = files.filter((file) =>
    ["image/gif", "image/webp"].includes(file.type)
  );

  if (!images.length) {
    return undefined;
  }

  return images;
}

export function useUploadExercises() {
  const { exercisesRepository } = useExercisesRepository();
  const [status, setStatus] = useState(IDLE_STATUS);
  const [error, setError] = useState(null);
  const loading = status !== IDLE_STATUS;

  let message = DEFAULT_MESSAGE;

  if (error) {
    message = `¡Error! ${error}.`;
  } else if (loading) {
    message = status;
  }

  async function onSuccess(acceptedFiles) {
    if (!acceptedFiles.length) {
      return;
    }

    setStatus("Procesando archivos...");

    const exerciseFile = findExerciseFile(acceptedFiles);
    const exerciseImages = findExerciseImages(acceptedFiles);

    // Create/Update exercises
    if (exerciseFile) {
      try {
        const { rows: exercises, errors } = await readXlsxFile(exerciseFile, {
          schema: exercisesSchema,
        });

        if (errors.length) {
          const errorColumns = errors.reduce((acc, current) => {
            return { ...acc, [current.column]: current.error };
          }, {});
          throw new Error(
            `Revisa la/s columna/s: ${Object.keys(errorColumns).join(", ")}`
          );
        }

        if (!exercises.length) {
          throw new Error("El archivo está vacio");
        }

        setStatus("Actualizando ejercicios...");
        await exercisesRepository.update(exercises);
      } catch (error) {
        console.error(error);
        setError(error.message);
        setStatus(IDLE_STATUS);
        return;
      }
    }

    // Upload images
    if (exerciseImages) {
      try {
        setStatus("Subiendo imágenes...");
        await exercisesRepository.uploadImages(exerciseImages);
      } catch (error) {
        console.error(error);
        setError(error.message);
        setStatus(IDLE_STATUS);
        return;
      }
    }

    setStatus(IDLE_STATUS);
  }

  function onFail(fileRejections) {
    if (!fileRejections.length) {
      return;
    }

    if (fileRejections.length > 1) {
      setError("Estás intenatando subir más de un archivo");
      return;
    }

    setError("El archivo no tiene un formato válido");
  }

  function onReset() {
    setError(null);
  }

  return {
    model: {
      title: DEFAULT_TITLE,
      message,
      loading,
      error,
    },
    actions: { onSuccess, onFail, onReset },
    config: {
      validFormats: VALID_FORMATS,
    },
  };
}
